import {ArreglarServicesList,GetPercentPayed} from '../sagas'

const defaultState={
    listaReservas:[],
    preciosXFechaOta:[],
    reserva:{},
    fecha:null,
    sidebarOpened:true,
    finalConsumerLimit:13115,
    finalConsumerLimitElectronic:26230,
    loadingBill:false,
    listaDeptos:[],
    billList:[],
    lastPriceChange:"0",
    listaGeneralDeptos:[],
    customerToBill:{},
    listaGeneralReservas:[],
    statisticsMonth:[],
    paymentToBill:null,
    listaCheckout:[],
    paymentList:[],
    preReservationList:[],
    paymentListStatistics:[],
    cashFromTo:{from:'',to:''},
    cashMovements:[],
    mapInfo:"",
    tipoPrecio:"",
    tipoPagos:[{tipo:'Efectivo',backgroundColor:'#febf63'},{tipo:'Tarjeta de crédito',backgroundColor:'#7fdbda'},{tipo:'Tarjeta de débito',backgroundColor:'#7fdbda'},{tipo:'Transferencia Bancaria',backgroundColor:'#ade498'},{tipo:'Mercadopago',backgroundColor:'#bc6ff1'},{tipo:'Tarjeta-Web',backgroundColor:'antiquewhite'}],
    priceLoading:false,
    rangStatisticsLoading:false,
    session:"",
    isAdmin:false,
    currentUser:"",
    mainLoading:false,
    preReservation:false,
    statisticsByDateLoading:false,
    statisticsByMonthLoading:false,
    paymentstatisticsloading:false,
    isPreReservation:false,
    paymentListDay:"",
    cashMovementLoading:false,
    paymentLoading:false,
    isCashClose:true,
    isCashAdmin:true,
    searchLoading:false,
    fastCheckin:true,
    listingLoading:false,
    fromStatistics:"",
    toStatistics:"",
    diaPlaya:true,
    currentCashName:'abajo',
    statisticsDate:'',
    generalStatisticsByDate:"",
    searchResult:[],
    reservationListScheduler:[],
    mapLink:"",
    checkoutTime:0,
    pageList:0,
    regularCustomer:"",
    loadingBar:false,
    changeDateTime:5,
    currentBuildNumber:0,
    lastBuildNumber:0,
    precios:[],
    campingName:"",
    withoutPlace:false,
    preciosXFecha:[],
    disponibilidadXFecha:[],
    divisa:0,
    informacionHotel:{},
    refrescarDispoYPrecios:0,
    isMobile:false,
    isFirstMobile:false,
    openCash:{date:'2021-01-16',amount:300,userName:'titiloxx'},
    closeCash:{},
};

export default function reducer(state=defaultState,{type,payload}){
    switch (type){
        case "INFORMACION_HOTEL":
            return {...state,informacionHotel:payload};
        case "SET_RESERVA":
            return {...state,reserva:payload};
        case "PRECIOS_X_FECHA":
            return {...state,preciosXFecha:payload};
        case "DISPONIBILIDAD_X_FECHA":
            return {...state,disponibilidadXFecha:payload};
        case "AUTOAJUSTE_X_FECHA":
            return {...state,autoAjuste:payload};
        case "SET_BILL_LIST":
            return {...state,billList:payload};
        case "SET_MODAL_RESERVA":
            return {...state,modal:payload};
        case "SET_LOADING_BILL":
            return {...state,loadingBill:payload};
        case "SET_PAYMENT_TO_BILL":
            return {...state,paymentToBill:payload};
        case "SET_CAMPING_NAME":
            return {...state,campingName:payload};
        case "ADD_LIST_TO_MAIN_RESERVATION_LIST":
            return {...state,listaReservas:[...state.listaReservas,...payload].reduce((acom,current)=>acom.find(x=>x.reservationId==current.reservationId)?acom:[...acom,current],[])};
        case "SET_LISTA_RESERVAS":
            return {...state,listaReservas:payload};
        case "SET_SIDEBAR":
            return {...state,sidebarOpened:payload};
        case "SET_RESERVATION_LIST_SCHEDULER":
            return {...state,reservationListScheduler:payload};
        case "SET_TIPO_PAGOS":
            return {...state,tipoPagos:payload};
        case "SET_FECHA":
            return {...state,fecha:payload};
        case "SET_RANGE_STATISTICS_LOADING":
            return {...state,rangStatisticsLoading:payload};
        case "PRECIOS_X_FECHA_OTA":
            return {...state,preciosXFechaOta:payload};
        case "SET_LOADING_BAR":
            return {...state,loadingBar:payload};
        case "SET_SEARCH_LOADING":
            return {...state,searchLoading:payload};
        case "SET_STATISTICS_BY_DATE_LOADING":
            return {...state,statisticsByDateLoading:payload};
        case "SET_STATISTICS_BY_MONTH_LOADING":
            return {...state,statisticsByMonthLoading:payload};
        case "SET_STATISTICS_DATE":
            return {...state,statisticsDate:payload};
        case "SET_STATISTICS_MONTH":
            return {...state,statisticsMonth:payload};
        case "SET_SEARCH_RESULT":
            return {...state,searchResult:payload};
        case "SET_TIPO_PRECIO":
            return {...state,tipoPrecio:payload};
        case "SET_CURRENT_CASH_NAME":
            return {...state,currentCashName:payload};
        case "SET_PAYMENT_LIST":
            return {...state,paymentList:payload};
        case "SET_PRE_RESERVATION":
            return {...state,preReservation:payload};
        case "SET_PRE_RESERVATION_LIST":
            return {...state,preReservationList:payload};
        case "SET_IS_PRE_RESERTVATION":
            return {...state,isPreReservation:payload};
        case "SET_PAYMENT_STATISTICS_LOADING":
            return {...state,paymentstatisticsloading:payload};
        case "SET_PAYMENT_LIST_STATISTICS":
            return {...state,paymentListStatistics:payload};
        case "SET_PAYMENT_LIST_DAY":
            return {...state,paymentListDay:payload};
        case "SET_CHECKOUT_LIST":
            return {...state,listaCheckout:payload};
        case "MAIN_LOADING":
            return {...state,mainLoading:payload};
        case "PRICES_LOADING":
            return {...state,priceLoading:payload};
        case "SET_CHECKOUT_TIME":
            return {...state,checkoutTime:payload};
        case "SET_ADMIN":
            return {...state,isAdmin:payload};
        case "SET_CAMPING_USER":
            return {...state,campingUser:payload};
        case "SET_CURRENT_USER":
            return {...state,currentUser:payload};
        case "SET_FROM_STATISTICS":
            return {...state,fromStatistics:payload};
        case "SET_TO_STATISTICS":
            return {...state,toStatistics:payload};
        case "SET_CASH_CLOSE":
            return {...state,isCashClose:payload};
        case "SET_SESSION":
            return {...state,session:payload};
        case "SET_PAGE_LIST":
            return {...state,pageList:payload};
        case "SET_LISTING_LOADING":
            return {...state,listingLoading:payload};
        case "SET_CASH_FROM_TO":
            return {...state,cashFromTo:payload};
        case "SET_CASH_MOVEMENT_LOADING":
            return {...state,cashMovementLoading:payload};
        case "SET_PAYMENT_LOADING":
            return {...state,paymentLoading:payload};
        case "SET_STATISTICS_BY_DATE":
            return {...state,generalStatisticsByDate:payload};
        case "SET_REGULAR_CUSTOMER":
            localStorage.setItem('regularCustomer', payload);
            return {...state,regularCustomer:payload};
        case "ROOM_TYPE":
            return {...state,roomType:payload};
        case "SET_PRECIOS":
            return {...state,precios:payload};
        case "SET_WITHOUT_PLACE":
            return {...state,withoutPlace:payload};
        case "SET_LISTA_DEPTOS":
            return {...state,listaDeptos:payload};
        case "SET_DIA_PLAYA":
            return {...state,diaPlaya:payload};
        case "SET_LAST_CHANGE":
            return {...state,lastChange:payload};
        case "SET_LAST_PRICE_CHANGE":
            return {...state,lastPriceChange:payload};
        case "SET_LISTA_GENERAL_DEPTOPS":
            return {...state,listaGeneralDeptos:payload};
        case "SET_LISTA_GENERAL_RESERVAS":
            return {...state,listaGeneralReservas:payload,listaReservas:payload};
        case "SET_IS_MOBILE":
            return {...state,isMobile:payload};
        case "SET_DIVISA":
            return {...state,divisa:payload};
        case "SET_PAYMENT_TO_BILL_NOW":
            return {...state,paymentToBillNow:payload};
        case "SET_REFRESCAR":
            return {...state,refrescarDispoYPrecios:Math.random()*100000};
        case "SET_SESSION":
            return {...state,sesion:payload};
        case "SET_CASH_MOVEMENTS":
            return {...state,cashMovements:payload};
        case "SET_LISTA_PRECIOS":
            return {...state,listaPrecios:payload};
        case "SET_CURRENT_BUILD_NUMBER":
            return {...state,currentBuildNumber:payload};
        case "SET_LAST_BUILD":
            return {...state,lastBuildNumber:payload};
        case "SET_MAP_INFO":
            return {...state,mapInfo:payload};
        case "SET_FIRST_MOBILE":
            return {...state,isFirstMobile:payload};
        case "ADD_SERVICE":
            const {reservationId,service}=payload;
            for(var reserva of state.listaReservas){
               
                if (reserva.id==reservationId){
                    reserva.servicesList=[...reserva.servicesList,service]
                }
            }
            return {...state,refrescarDispoYPrecios:Math.random()*100000};
        case "ADD_PAYMENT":
            const {serviceId,payment}=payload;
            for(var reserva of state.listaReservas){
                const service=reserva.servicesList.find(x=>x.serviceId==serviceId);
                if (service){
                    service.paymentsList=[...service.paymentsList,payment]
                    reserva.servicesList=ArreglarServicesList(reserva.servicesList);
                    reserva.percentPayed=GetPercentPayed(reserva.servicesList.filter(x=>!x.canceled));
                }
            }
         
            return {...state,refrescarDispoYPrecios:Math.random()*100000};
        default:
            return state
    }
}