import React from 'react'
import moment from "moment"
import store from '../redux/store.js'
import { Label} from 'semantic-ui-react'
import {API,graphqlOperation} from 'aws-amplify';
import {buscarxdni,reservationList,roomsList,getPrices,getMapInfo,getPaymentList} from "../graphql/queries"
import {addReservation,modifyReservation,modifyVehicles,addService,addPayment,addRoom,modifyRoom,modifyPrices} from "../graphql/mutations"
import {addedReservation,addedService,addedPayment,modifiedReservation,addedVehicles} from "../graphql/subscriptions"
import listaIngresosAction from '../redux/actions/listaIngresos'
import {ArreglarServicesList,GetPercentPayed} from '../redux/sagas'
import { CheckinSignature,TicketFacturaB,CheckoutSignature,TicketX } from './printerTemplates.js';
import qz from "qz-tray";

//ACTIONS
import setReserva from '../redux/actions/setReserva'
import refrescarPrecioYDispo from '../redux/actions/refrescarPrecioYDispo'
import setMapInfo from '../redux/actions/setMapInfo'
import setPrecios from '../redux/actions/setPrecios'

export const ActualizarTabla=(setLoading,dispatch)=>{
    // Actualiza el título del documento usando la API del navegador
    API.graphql({
      query: reservationList,
      variables:{tipo:"camping"},
      authMode: 'AMAZON_COGNITO_USER_POOLS'
    }).
    then(res => {
      setLoading(false);
       const lista=res.data.reservationList;
       dispatch(listaIngresosAction(lista));
    }).catch((x)=>{setLoading(false)})
  }

export const ParseScan=(str)=>{
  try {
    const obj=str.split("@").join("\"").split("\"");
    if (obj.length>11) {
      return {
      fullName:`${obj[5].trim()} ${obj[4].trim()}`,
      sex:obj[8].trim(),dni:obj[1].trim(),bday:obj[7].trim()}
    }
    return {fullName:`${obj[2].trim()} ${obj[1].trim()}`,
    sex:obj[3].trim(),dni:obj[4].trim(),bday:obj[6].trim()}
  } catch (error) {
    //Cuando tira error parseamos de distinta forma
    const obj=str.split("@").join("\"").split("\"")[0].split(' ');
    let fullName=""
    let sex=""
    let foundSex=false
    let bday=""
    let dni=""
    let acom=0 
    for (let item of obj) {
      acom++;
      if(acom==5){
        dni=item;
      }
      if (item=="F"||item=="M") {
        sex=item;
        foundSex=true;
        continue;      }
      if (!foundSex) {
        fullName=fullName+item+" ";
      }
      else{
        if(item.split('/').length==3){
          bday=item;
          break;
        }
      }
      
    }
    fullName=fullName.trim()
    return({fullName,sex,bday,dni})
  }}
export const ParseScanPassport=(str)=>{
  
    const fullName=str.lastName+" "+str.firstName;
    const sex=str.sex;
    const dni=str.documentNumber.split('.').join("");
    const bday=(str.dateOfBirth.year<1901?str.dateOfBirth.year+100:str.dateOfBirth.year)+"-"+
    (str.dateOfBirth.month<10?"0"+str.dateOfBirth.month:str.dateOfBirth.month)+"-"+
    (str.dateOfBirth.day<10?"0"+str.dateOfBirth.day:str.dateOfBirth.day);
    return {fullName,sex,dni,bday}
  }

export const ActualizarListaReservas=async (setLoading,dispatch)=>{
  const {tipoPrecio}=store.getState().mainReducer;

    // Actualiza el título del documento usando la API del navegador
    try {

      const response = await API.graphql({
        query: reservationList,
        variables:{type:tipoPrecio},
        authMode: 'AMAZON_COGNITO_USER_POOLS'
      })
      
      dispatch({type:"LISTA_RESERVAS",payload:response.data.reservationList});
      setLoading(false)
    } catch (error) {
      console.log(error)
    }
  }

export const ActualizarMapInfo=async (dispatch)=>{
    const response = await API.graphql({
      query: getMapInfo,
      variables:{},
      authMode: 'AMAZON_COGNITO_USER_POOLS'
    })
    dispatch(setMapInfo(JSON.parse(response.data.getMapInfo)));
  }

export const ActualizarListaDeptos=async (setLoading,dispatch)=>{
    // Actualiza el título del documento usando la API del navegador
    const response = await API.graphql({
      query: roomsList,
      variables:{},
      authMode: 'AMAZON_COGNITO_USER_POOLS'
    })
    const listaDeptos=response.data.roomsList;
    dispatch({type:"SET_LISTA_DEPTOS",payload:listaDeptos});

    const responseReservas = await API.graphql({
          query: reservationList,
          variables:{type:"departamento"},
          authMode: 'AMAZON_COGNITO_USER_POOLS'
        })

    const listaReservas=responseReservas.data.reservationList;
    listaReservas.forEach(x => {
      x.roomsList=x.roomsList.map(y=>{
          if (y.roomId){
            return listaDeptos.find(x=>x.roomId==y.roomId)
          }
            return listaDeptos.find(x=>x.roomId==y)
        })
      });
    dispatch({type:""});
    setLoading(false)
  }


  export const MandarFormularioDepto=async (ingreso,setLoading,dispatch,testFunction,makeBill,onComplete=()=>{},nuevaReserva=false)=>{
    delete ingreso.nights
    delete ingreso.nightsDue
    const {listaGeneralReservas:listaReservas,tipoPrecio}=store.getState().mainReducer;
    const billSession=store.getState().billReducer;
    const {printer}=store.getState().printerReducer;
    ingreso.type=tipoPrecio;
    ingreso.regularCustomer=store.getState().mainReducer.regularCustomer?Math.floor(Math.random()*10000):"";
    const reservation={reservation:ingreso}
      //Sirve para los test
      if  (testFunction){
        testFunction(ingreso)
      }
      else{
        try{
        const res=await API.graphql(graphqlOperation(addReservation,reservation))
        setLoading(false);
        if (res.data.addReservation)
        {
          await dispatch({type:"LISTA_RESERVAS",payload:[...listaReservas,res.data.addReservation]})
               
          if (nuevaReserva) {
            const listaDeptos=store.getState().mainReducer.listaGeneralDeptos;
            const nuevoIngreso=MejorarIngreso(res.data.addReservation,listaDeptos);
            await dispatch(setReserva(nuevoIngreso));
            if(billSession.toggleBilling&&makeBill){
              await dispatch({type:"SET_PAYMENT_TO_BILL_NOW",payload:nuevoIngreso.servicesList[0].paymentsList[0]});
            }
            if (printer.isConnected) {
              if (printer.printCheckin) {
                printPOS(nuevoIngreso,"checkin")
              }
              if (printer.printCheckout) {
                printPOS(nuevoIngreso,"checkout")
              }
              if (printer.printTicket&&!billSession.toggleBilling) {
                printPOS(nuevoIngreso.servicesList[0],"ticketx")
              }
            }
            onComplete()
          }
        }
        else{
          alert("Hubo un error y no se pudo agregar")
          }
        }
          catch(error){
            console.log(error)
            if (error?.errors[0].message.split('email').length===3) {
              alert('Uno de los email ingresados no es valido')
            }          
            if (error?.errors[0].message=="Network Error") {
              alert('No se pudo cargar el ingreso debido a un error de conexion')
            }          
            else{
              alert('No se pudo cargar el ingreso. Por favor vuelve a intentarlo');

            }  
            setLoading(false);
          };
      }
  }

  export const CargarDNI=(dni,setLoading,setNombre)=>{
    setLoading(true)
    API.graphql(
      graphqlOperation(buscarxdni,{dni})).
      then(res => {
        setLoading(false);
        setNombre(res.data.buscarxdni)
     
      }).
      catch(error=>{
        setLoading(false);
        alert(error.errors[0].message);
      });
  }

export const ActualizarVehiculos=async (reservationId,vehiclesList,dispatch)=>{
  const vehiclesListAux=vehiclesList.map(x=>{const {tableData,...y}=x;return(y)})
  const response=await API.graphql(graphqlOperation(modifyVehicles,{reservationId,vehiclesList:vehiclesListAux}))
  ActualizarListaDeptos(()=>{},dispatch)
} 

export const AgregarNochesReserva=async (reservation,noches)=>{
  const reservationAux=JSON.parse(JSON.stringify(reservation))
  reservationAux.checkoutEstimated=
  CambiarEstadoReserva(reservationAux,()=>{},()=>{},()=>{},null)
} 

export const GetListaHorarios=()=>{
  let listaHorarios=''
  for (let y of ['01','02','03','04','05','06','07','08','09','10','11','12','13','14','15','16','17','18','19','20','21','22','23','24']) {
    listaHorarios=[...listaHorarios,`${y}:00`]
  }
  return listaHorarios
} 

export const SacarDeLista=async(lista,setLista,valor)=>{
  if (window.confirm(`¿Seguro desea eliminar el lugar ${valor.number} de este registro?`)){
  setLista(lista.filter(x=>x!=valor))
  }
}
export const CalcularDeuda=(ingreso)=>{
  let deuda=ingreso.servicesList.filter(x=>x.due!=0&&!x.canceled).map(x=>x.due).reduce((a,b) => a + b, 0);
  if (deuda>0) {
    return deuda
  }
  else if(ingreso.nightsDue>0&&ingreso.servicesList.filter(x=>!x.canceled).length>0){
    return ingreso.servicesList.filter(x=>!x.canceled)[0].cost*ingreso.nightsDue
  }
  else{
    return 0
  }

}

export const AgregarServicios=async (reservationId,serviceAux,dispatch,setLoading,agregarNoches,setService=()=>{})=>{
  try {
    const reservaState=store.getState().mainReducer.reserva
    serviceAux.paymentsList=[]
    const response=await API.graphql(graphqlOperation(addService,{reservationId,service:serviceAux,addDays:agregarNoches}))
    const listaReservas=JSON.parse(JSON.stringify(store.getState().mainReducer.listaGeneralReservas))
    const service=response.data.addService
      reservaState.servicesList=[...reservaState.servicesList,service]
      reservaState.servicesList=ArreglarServicesList(reservaState.servicesList)
      reservaState.checkoutEstimated=moment(reservaState.checkoutEstimated).add(agregarNoches,'days').format();
      if (reservaState.state=='preingreso') {
      const preReservationList=store.getState().mainReducer.preReservationList
      if (preReservationList.find(x=>x.reservationId==reservaState.reservationId)) {
        await dispatch({type:"PRE_RESERVATION_LIST",payload:[...preReservationList.filter(x=>x.reservationId!=reservaState.reservationId),reservaState]});
      }
    }
  await dispatch({type:"LISTA_RESERVAS",payload:[...listaReservas.filter(x=>x.reservationId!=reservaState.reservationId),reservaState]});
  const reserva=store.getState().mainReducer.listaGeneralReservas.find(x=>x.reservationId==reservationId);
  await dispatch(setReserva(reserva));
  setLoading(false)
  } catch (error) {
    console.log(error)
    alert("Error inesperado")
    setLoading(false)
  }

} 
export const BuscarReserva=(reservationId)=>{
  const reserva=store.getState().mainReducer.listaGeneralReservas.find(x=>x.reservationId==reservationId);
  return reserva;
}

export const AgregarYPagarServicios=async (reservationId,serviceAux,dispatch,setLoading,agregarNoches,paymentAux,makeBill,onComplete)=>{
  try {
    const {reserva:reservaState}=store.getState().mainReducer
    const {printer}=store.getState().printerReducer
    const billSession=store.getState().billReducer
    serviceAux.paymentsList=[]
    const response=await API.graphql(graphqlOperation(addService,{reservationId,service:serviceAux,payments:[paymentAux],addDays:agregarNoches}))
    const listaReservas=JSON.parse(JSON.stringify(store.getState().mainReducer.listaGeneralReservas))
    const service=response.data.addService
        service.paymentsList=response.data.addService.paymentsList;
        reservaState.servicesList=[...reservaState.servicesList,service]
        reservaState.servicesList=ArreglarServicesList(reservaState.servicesList)
        //Bill
        if(billSession.toggleBilling&&makeBill){
          await dispatch({type:"SET_PAYMENT_TO_BILL_NOW",payload:service.paymentsList[0]});
        }
        //Print ticket
        if (printer.printTicket&&!billSession.toggleBilling) {
          printPOS(service,"ticketx")
        }
        reservaState.percentPayed=GetPercentPayed(reservaState.servicesList);
        reservaState.checkoutEstimated=moment(reservaState.checkoutEstimated).add(agregarNoches,'days').format();
        if (reservaState.state=='preingreso') {
          const preReservationList=JSON.parse(JSON.stringify(store.getState().mainReducer.preReservationList))
          if (preReservationList.find(x=>x.reservationId==reservaState.reservationId)) {
            await dispatch({type:"PRE_RESERVATION_LIST",payload:[...preReservationList.filter(x=>x.reservationId!=reservaState.reservationId),reservaState]});
          }
        }
  await dispatch({type:"LISTA_RESERVAS",payload:[...listaReservas.filter(x=>x.reservationId!=reservaState.reservationId),reservaState]});
  const reserva=store.getState().mainReducer.listaGeneralReservas.find(x=>x.reservationId==reservationId);
  await dispatch(setReserva(reserva));
  //await AgregarPagos(service.serviceId,paymentAux,dispatch,()=>{},()=>{})
  } catch (error) {
    console.log(error)
    if (error?.errors&&error?.errors[0]?.message=='Network Error') {
      alert("¡Error de internet, vuelve a intentarlo!")
     }
     else{
      alert("Error inesperado agregando pago")
     }
  
  }
  setLoading(false)

} 

export const AgregarPagos=async (serviceId,paymentAux,dispatch,setLoading,onComplete)=>{
  try {
    const reserva=store.getState().mainReducer.reserva
    const response=await API.graphql(graphqlOperation(addPayment,{serviceId,payment:paymentAux}))
    const payment=response.data.addPayment;
    //dispatch(addPaymentAction({serviceId,payment:response.data.addPayment}))
    const listaReservas=JSON.parse(JSON.stringify(store.getState().mainReducer.listaGeneralReservas))
    var reservaAux={}
    let reservationId;
        const service=reserva.servicesList.find(x=>x.serviceId==serviceId);
        if (service){
            reservationId=reserva.reservationId;
            service.paymentsList=[...service.paymentsList,payment]
            reserva.servicesList=ArreglarServicesList(reserva.servicesList);
            reserva.percentPayed=GetPercentPayed(reserva.servicesList);
            reservaAux=JSON.parse(JSON.stringify(reserva));
    }
    if (reserva.state!='preingreso') {
      await dispatch({type:"LISTA_RESERVAS",payload:[...listaReservas.filter(x=>reserva.reservationId!=x.reservationId),reserva]});
    }
    else{
      const preReservationList=JSON.parse(JSON.stringify(store.getState().mainReducer.preReservationList))
      if (preReservationList.find(x=>x.reservationId==reserva.reservationId)) {
        await dispatch({type:"PRE_RESERVATION_LIST",payload:[...preReservationList.filter(x=>x.reservationId!=reserva.reservationId),reserva]});
      }
    }

    dispatch(setReserva(MejorarIngreso(reserva)));
    setLoading(false)
    onComplete();
  } catch (error) {
   if (error?.errors&&error?.errors[0]?.message=='Network Error') {
    alert("¡Error de internet, vuelve a intentarlo!")
   }
   else{
    alert("Habia otro pago identico anteriormente, no se pudo cargar este, actualice la aplicacion para verlo reflejado")
   }
   setLoading(false)
  }

} 

export const ListaFechas=(fechaInicio,fechaFin,everyXTime=1)=>{
  let lista=[]
  const noches=moment(fechaFin).diff(fechaInicio,'days')+1
  for (let index = 0; index < noches/everyXTime; index++) {
    lista=[...lista,moment(fechaInicio).add(index*everyXTime,'days').format('YYYY-MM-DD')]
  }
  return lista;
}

export const CambiarEstadoCheckinReserva=async (reservationId,estado,setLoading,dispatch)=>{
  const reservation=store.getState().mainReducer.listaReservas.find(x=>x.reservationId==reservationId);
  console.log(reservation);
  switch (estado) {
    case "checkin":
        reservation.checkinMade=moment().format()
        reservation.checkoutMade=null
        reservation.state="checkin"
        break;
    case "checkout":
        reservation.checkoutMade=moment().format()
        reservation.state="checkout"
        break;
    case "confirmada":
        reservation.state="confirmada"
        reservation.checkoutMade=null
        reservation.checkinMade=null
        break;
    case "cancelada":
        reservation.state="cancelada"
        break;

  }  
  await CambiarEstadoReserva(reservation,setLoading,()=>{
  },dispatch)
}

export  const CambiarEstadoReserva=async (ingreso,setLoading,onComplete,dispatch,testFunction)=>{
  const {listaReservas,preReservationList}=store.getState().mainReducer;
  const {nights,created,servicesList,vehiclesList,percentPayed,isDeudor,otaId,nightsDue,...ingresoAux}=ingreso;
  ingresoAux.customersList=ingresoAux.customersList.map(x=>{const {age,...aux}=x;return(aux)})
  const reservation={reservation:ingresoAux}
 // ingreso.regularCustomer=store.getState().mainReducer.dormis.regularCustomer?Math.floor(Math.random()*10000):"";
    setLoading(true)
    try {
      const response=await API.graphql(graphqlOperation(modifyReservation,reservation));
      if (response.data.modifyReservation){
          //Sacamos el modificado de la lista
          const listaNueva=listaReservas.filter(x=>x.reservationId!=ingreso.reservationId)
          await dispatch({type:"LISTA_RESERVAS",payload:[...listaNueva,ingreso]})
          if (ingreso.state!='preingreso') {
            await dispatch({type:"PRE_RESERVATION_LIST",payload:preReservationList.filter(x=>x.reservationId!=ingreso.reservationId)});
          }
          const listaDeptos=store.getState().mainReducer.listaGeneralDeptos;
          await dispatch(setReserva(MejorarIngreso(ingreso,listaDeptos)));
          onComplete();
      }
      else{
        alert("Hubo un error")
      }
    } catch (error) {
      if (error?.errors[0].message.split('email').length===3) {
        alert('Uno de los email ingresados no es valido')
        if (error?.errors[0].message=="Network Error") { 
        alert('Error: No hay conexion a internet')  
        return; }
      }
      
    }
    setLoading(false);

  }

export function CapitalFirst(string) 
{
    return string.charAt(0).toUpperCase() + string.toLocaleLowerCase().slice(1);
}

export function Normalizar(string){
 return string.toLocaleLowerCase().split("'").join("").split("-").join(" ").split(" ").map(x=>CapitalFirst(x)).join(" ")
}



export function ElegirColor(estado,isDeudor){
  switch (estado){
    case "confirmada":
      return "#2185d0"
    case "checkin":
      if (isDeudor) {
        return "#fbbd08"
      }
      else{
        return "#21ba45"
      }
    case "cancelada":
      return "#db2828"
    case "checkout":
      return "#a5673f"
    case "nopresentado":
      return "#f2711c"
    case "abierta":
      return "#a333c8"

  }
}
export function ElegirColorMapa(estado,isDeudor){
  switch (estado){
    case "confirmada":
      return "#00aae4"
    case "checkin":
      if (isDeudor) {
        return "#ffd700"
      }
      else{
        return "#df6b77"
      }
    case "cancelada":
      return "#db2828"
    case "nopresentado":
      return "#db2828"
    case "checkout":
      return "#a5673f"
    default:
      return ''
  }
}

export function ElegirColorSemantic(estado,isDeudor){
  if (estado=='checkin'&&isDeudor) {
    return `yellow`
  }
  switch (estado){
    case "confirmada":
      return "blue"
    case "abierta":
      return "purple"
    case "checkin":
      return "green"
    case "cancelada":
      return "red"
    case "nopresentado":
      return "orange"
    case "checkout":
      return "brown"
    case "preingreso":
      return "pink"

  }
 
}

export function MejorarIngreso(ingreso,listaDeptos){
  const checkoutTime=store.getState().mainReducer.checkoutTime
      /* const nochesPagas=ingreso.listaPagos.map(x=>x.noches).reduce((a,b) => a + b, 0);
      const fechaSalidaPaga=moment(ingreso.fechaIngreso).add(nochesPagas,"days").format("YYYY-MM-DD")*/
  let nights=moment(ingreso.checkoutEstimated).startOf('day').diff(moment(ingreso.checkinEstimated).startOf('day'),"days");  

  //NightsUntilNow is used to calculate if the reservation is overdue
  let nightsUntilNow=(moment().startOf('day').diff(moment(ingreso.checkinEstimated).startOf('day'),"days"))+(moment().format('YYYY-MM-DD')==moment(ingreso.checkoutEstimated).format('YYYY-MM-DD')&&(parseInt(moment().format('HH'))<(parseInt(moment(ingreso.checkoutEstimated).format('HH'))+3))?0:1);
  nightsUntilNow=nightsUntilNow>0?nightsUntilNow:0;
  // if(ingreso.customersList.find(x=>x.fullName.split('Galvan').length>1)){
  //   console.log(ingreso,nightsUntilNow,`FECHA ENTRADA: ${moment(ingreso.checkinEstimated).format('DD-MM')}`,`HORA SALIDA: ${moment(ingreso.checkoutEstimated).format('HH')}`)
  // }



  
  const customersList=ingreso.customersList.map(x=>({...x,
      age:x.birthdate?moment().diff(x.birthdate,"years"):null}))
  var servicesList=ArreglarServicesList(ingreso.servicesList)

  //Prepare list
  const servicesNotPreingreso=servicesList.filter(x=>x.description!='Pre-ingreso');
  let unPayedServices=servicesNotPreingreso.filter(x=>!x.canceled&&x.quantity!=0)

  const payedNights=unPayedServices.length>0?Math.floor(unPayedServices.reduce((acom,current)=>acom+(current.due==0?current.quantity:(current.paid)/(current.perNight)),0)):0

  
  const percentPayed=GetPercentPayed(servicesNotPreingreso);

    
  let nightsDue=nightsUntilNow-payedNights;

  //const isDeudor=((percentPayed!=100||moment(ingreso.checkoutEstimated).add(3,"hours").diff(moment(),'minutes')<0)&&ingreso.state=="checkin");
  const isDeudor=nightsDue>0;
  const checkoutEstimated=ingreso.state=="preingreso"?moment(ingreso.checkoutEstimated).set('hour',checkoutTime-3).format():ingreso.checkoutEstimated
  const startOfDay=moment().startOf('day')
  const startOfDayReservation=moment(ingreso.checkinEstimated).startOf('day')
  const numberOfDays=parseInt(startOfDay.diff(startOfDayReservation,"days"));
  const reservationNewState=(ingreso.state=='confirmada'||ingreso.state=='nopresentado')?(numberOfDays>1?'nopresentado':'confirmada'):ingreso.state;

  return {...ingreso,
      state:reservationNewState,
      nights,
      isDeudor,
      nightsDue,
      customersList,
      checkoutEstimated,
      servicesList,
      percentPayed}
}

export function ElegirLabel(estado,nightsDue){

  const colorSemantic=ElegirColorSemantic(estado,nightsDue>0);
  switch (estado){
    case "confirmada":
      return   <Label  style={{margin:0}} color={colorSemantic} horizontal >
      Reserva
    </Label>
    case "abierta":
      return   <Label  style={{margin:0}} color={colorSemantic} horizontal >
      Abierta
    </Label>
    case "checkin":
      return  <Label  style={{margin:0}} color={colorSemantic}  horizontal >
      {nightsDue>0?"Adeuda":`Checkin`}
      {nightsDue>0&&<Label.Detail className={"deudor-label"} >{nightsDue}</Label.Detail>}
    </Label>
    case "cancelada":
      return     <Label style={{margin:0}}  color={colorSemantic}  horizontal >
      Cancelada
    </Label>
    case "nopresentado":
      return     <Label style={{margin:0}}  color={colorSemantic}  horizontal >
      No presentado
    </Label>
    case "checkout":
      return  <Label style={{margin:0}} color={colorSemantic}  horizontal >
      Checkout
    </Label>
    case "preingreso":
      return  <Label style={{margin:0}} color={colorSemantic}  horizontal >
      Pre Ingreso
    </Label>
    default:
      return  <Label style={{margin:0}} color={"grey"}  horizontal >
      {estado}
    </Label>

  }
 
}
export function ElegirTexto(estado,nightsDue){
  switch (estado){
    case "confirmada":
      return "Reserva"
    case "abierta":
      return "Abierta"
    case "checkin":
      return nightsDue<0?"Checkin":`Adeuda ${nightsDue}`
    case "cancelada":
      return "Cancelada"
    case "nopresentado":
      return "No presentado"
    case "checkout":
      return "Checkout"
    case "preingreso":
      return "Pre Ingreso"
    default:
      return estado
  
  }
 
}


export function ElegirOtaLabel(estado){

  switch (estado){
    case "booking":
      return   <Label  style={{marginLeft:'10px'}} color={'grey'} horizontal>
      Booking
    </Label>
    case "Pagina-Web":
      return   <Label style={{marginLeft:'10px'}} color={'grey'} horizontal>
      Pagina Web
    </Label>
    default:
      return  <Label style={{marginLeft:'10px'}} color={'grey'} horizontal>
      Mostrador
    </Label>

  }
 
}

export const InformacionReservas=(listaReservas,fecha)=>{
  const fechaMoment=moment(fecha);
  var salen=[]
  var entran=[]
  var estancias=[]
  for (var item of listaReservas.filter(x=>x.state!='cancelada')) {
    if (moment(item.checkoutEstimated).format("YYYY-MM-DD")==fechaMoment.format("YYYY-MM-DD")){
      salen=[...salen,item]
    }
    else if(moment(item.checkinEstimated).format("YYYY-MM-DD")==fechaMoment.format("YYYY-MM-DD")){
      entran=[...entran,item]
    }
    else if(fechaMoment.isBetween(item.checkinEstimated,item.checkoutEstimated)){
      estancias=[...estancias,item]
    }
  }
  return {salen,entran,estancias}

}
export const InformacionReservasMapa=(listaReservas,fecha)=>{
  const fechaMoment=moment(fecha);
  var salen=[]
  var entran=[]
  var estancias=[]
  for (var item of listaReservas) {
    if (item.checkoutMade!=null||item.state=="cancelada") {
        continue;
    }
    if (moment(item.checkoutEstimated).format("YYYY-MM-DD")==fechaMoment.format("YYYY-MM-DD")){
      salen=[...salen,item]
    }
    else if(moment(item.checkinEstimated).format("YYYY-MM-DD")==fechaMoment.format("YYYY-MM-DD")){
      entran=[...entran,item]
    }
    else if(fechaMoment.isBetween(item.checkinEstimated,item.checkoutEstimated)){
      estancias=[...estancias,item]
    }
  }
  return {salen,entran,estancias}
}

export const InformacionReservasActual=(listaReservas)=>{
  return {salen:listaReservas.filter(x=>!x.checkoutMade&&x.state!="cancelada"&&moment(x.checkinEstimated).startOf('day').diff(moment().startOf('day'),'days')<1),entran:[],estancias:[]};
}
export const HabitacionesDisponiblesXFecha=(fechaIngreso,fechaSalida)=>{
  const listaFechas=ListaFechas(fechaIngreso,fechaSalida);
  let obj=[]
  for (let fecha of listaFechas) {
    obj=[...obj,{fecha,disponibilidad:HabitacionesDisponibles(fecha,moment(fecha).add(1,'days')).length}]
    
  }
  return obj;
}

export const HabitacionesDisponibles=(fechaIngreso,fechaSalida,listaReservas=null)=>{
  fechaSalida=moment(fechaSalida)
  fechaIngreso=moment(fechaIngreso)
  if(listaReservas==null){
    listaReservas=store.getState().mainReducer.listaReservas.filter(x=>x.state!='cancelada'&&x.state!='checkout')
  }
  
  //Los departamentos que estan tomados para las fechas
  var listaDeptos=[]
  const cantidadDias=fechaSalida.diff(fechaIngreso,"days")
  
  for (var i = 0; i < cantidadDias; i++) {
    const infoFecha=InformacionReservas(listaReservas,fechaIngreso)
    listaDeptos=[...listaDeptos,...infoFecha.entran.map(x=>x.roomsList).reduce((a,b)=>[...a,...b],[]),...infoFecha.estancias.map(x=>x.roomsList).reduce((a,b)=>[...a,...b],[])]
    fechaIngreso.add(1,"days");
  }
  var listaReturn=[]
  const departamentos=store.getState().mainReducer.listaDeptos;
  for (var item of departamentos){
    
    if (!listaDeptos.find(x=>x.number==item.number)){
        listaReturn=[...listaReturn,item]
    }

  }
  return listaReturn
}

export const PuedeReservar=(checkinEstimated,checkoutEstimated,reserva)=>{
//Lista de reservas sin la reserva
const listaReservasAlterada=store.getState().mainReducer.listaReservas.filter(x=>reserva.reservationId!=x.reservationId&&x.state!='cancelada'&&x.state!='checkout');
//Habitaciones disponibles sin tener en cuenta las habitaciones alquiladas por la reserva
const habitacionesDisponibles=HabitacionesDisponibles(checkinEstimated,checkoutEstimated,listaReservasAlterada);

for (var room of reserva.roomsList) {
  //Si no encuentra las habitaciones es porque no hay lugar
  if(habitacionesDisponibles.find(x=>x.roomId==room.roomId)==null){
      return {resultado:false,habitacion:room};
  }
  
}
return {resultado:true};
}

export const ActualizarTodo=async (setLoading,dispatch)=>{
   await ActualizarListaDeptos(()=>{},dispatch)
   await ActualizarListaReservas(setLoading,dispatch)
}

export const AgregarHabitacion=async (room,setLoading,dispatch)=>{
  setLoading(true)
  const response=await API.graphql(graphqlOperation(addRoom,{room}))
  ActualizarTodo(setLoading,dispatch)
}

export const ModificarHabitacion=async (room,setLoading,dispatch)=>{
  setLoading(true)
  const response=await API.graphql(graphqlOperation(modifyRoom,{room}))
  ActualizarTodo(setLoading,dispatch)
}

export const RefrescarPrecioYDispo=async (dispatch)=>{
  dispatch(refrescarPrecioYDispo(0));
}

export const SetSubscriptions= (dispatch)=>{
  const Actualizar=()=>{
    dispatch({type:'RELOAD_RESERVATIONS',payload:""})
    RefrescarPrecioYDispo(dispatch)
  }
  if (window.listaSubscription&&window.listaSubscription.length>0) {
    window.listaSubscription.map(x=>x.unsubscribe())
  }
    const listSub=[API.graphql(
      graphqlOperation(addedReservation)
      ),
      API.graphql(
        graphqlOperation(addedService)
        ),
      API.graphql(
        graphqlOperation(addedPayment)
        ),
      API.graphql(
        graphqlOperation(modifiedReservation)
        ),
      API.graphql(
        graphqlOperation(addedVehicles)
        )
    ]
    const listaAux=listSub.map(x=>x.subscribe({
      next: (todoData) => {
        Actualizar();
        //console.log('caca')
        // Do something with the data
      },
      error: error => {
        //alert('Aplicacion desconectada del servidor, actualiza para reconectar')
        //SetSubscriptions(dispatch)
      }
  }))
  window.listaSubscription=listaAux

}

export const ActualizarPrecios=async (dispatch)=>{
  const response = await API.graphql({
    query: getPrices,
    variables:{},
    authMode: 'AMAZON_COGNITO_USER_POOLS'
  })
  dispatch(setPrecios(JSON.parse(JSON.parse(response.data.getPrices))));
}

export const ModificarPrecios=async (precios,dispatch,setLoading)=>{
  // Actualiza el título del documento usando la API del navegador
  const response = await API.graphql(graphqlOperation(modifyPrices,{prices:JSON.stringify(precios)}))
  dispatch(setPrecios(JSON.parse(response.data.modifyPrices)));
  setLoading(false)
}
export const DevolverComponentesParaJSX=(vehiclesList,customersList,ingreso,servicesList)=>{
  const {precios,tipoPrecio}=store.getState().mainReducer;
  const obj = {}
  let comp = DevolverComponentes({...ingreso,vehiclesList,customersList});

  let compAux = JSON.parse(JSON.stringify(comp))
  compAux = DevolverComponentesConServicios(compAux, servicesList);

  Object.keys(comp).forEach((element) => {
      if (precios.find(x => x.type == tipoPrecio).priceList.find(x => x.name == element)) {
          obj[element] = compAux[element]
      }
  });
    //Sort by date desc using moment
  const servicesNotCanceled=servicesList.filter(x=>!x.canceled).sort((a,b)=>moment(b.date).diff(moment(a.date),"seconds"));

  const rta=servicesNotCanceled.length == 0 ? obj : JSON.parse(servicesNotCanceled[0].components);

  return rta
}

export const EsComponenteExtra=(componenteString)=>{
  const {precios,tipoPrecio}=store.getState().mainReducer;

  const priceList=precios.find(x=>x.type==tipoPrecio).priceList
  const component=priceList.find(x=>x.name==componenteString);
  return !!component&&Object.keys(component).length<3;
}

export const EsLugar=(componenteString)=>{
  const {precios,tipoPrecio}=store.getState().mainReducer;

  const priceList=precios.find(x=>x.type==tipoPrecio).priceList
  const component=priceList.find(x=>x.name==componenteString);
  return !!component.sector;
}

export const EsVehiculo=(componenteString)=>{
  const {precios,tipoPrecio}=store.getState().mainReducer;

  const priceList=precios.find(x=>x.type==tipoPrecio).priceList
  const component=priceList.find(x=>x.name==componenteString);
  return !!component.vehicleBased;
}

//En base a un ingreso devuelve todos sus componentes
export const DevolverComponentes=(ingreso)=>{
  const {precios,tipoPrecio}=store.getState().mainReducer;

  const prices=precios.find(x=>x.type==tipoPrecio).priceList
  const customersList=ingreso.customersList.map(x=>({...x,age:x.birthdate?moment().diff(x.birthdate,'years'):20}))
  const vehiclesList=ingreso.vehiclesList
  const obj={}
  let arrayValues=prices;
  let arrayKeys=prices.map(x=>x.name);
  const isInRange=(age,from,to)=>age>=from&&age<=to
  for (let key of arrayKeys) {
          obj[key]=0;
  }
  for (var customer of customersList)
  {
      for (let index = 0; index < arrayValues.length; index++) {
          const val = arrayValues[index];
          const key = arrayKeys[index];
          if (val.sexBased&&
              isInRange(customer.age,val.fromAge,val.toAge)&&
              customer.sex==val.sex) {
              obj[key]++;
          }
          else if (val.ageBased&&
              isInRange(customer.age,val.fromAge,val.toAge)) {
              obj[key]++;
           
          }
      }
  }
  for (var vehicle of vehiclesList)
  {
      for (let index = 0; index < arrayValues.length; index++) {
          const val = arrayValues[index];
          const key = arrayKeys[index];
          if (val.vehicleBased&&
              vehicle.type==key) {
              obj[key]++;
          }
      }
  }

  if(prices.filter(x=>x.quantity).length>0){
    for (let key of Object.keys(obj)) {
      //Find greates quantity in prices
      const maxQuantity=prices.filter(x=>x.quantity).reduce((a,b)=>a.quantity>b.quantity?a:b)
      const quantity=prices.find(x=>x.name==key).quantity
      const quantityExtra=prices.find(x=>x.quantityExtra)
      if (quantity&&
          customersList.length!=quantity) {
        obj[key]=0;
      }
      else if(customersList.length==quantity){
        obj[key]=1;
      }
      if(customersList.length>maxQuantity.quantity&&
        maxQuantity.name==key){
        obj[key]=parseInt(customersList.length/maxQuantity.quantity);
        if(quantityExtra){
          obj[quantityExtra.name]+=customersList.length-maxQuantity.quantity
        }
      }
    }
  }
  // console.log(ingreso,prices)
  if(prices.filter(x=>x.sector).length>0&&ingreso.roomsList.length>0){
    for (let key of Object.keys(obj)) {
      const sector=prices.find(x=>x.name==key)?.sector
      if (sector) {
        obj[key]=ingreso.roomsList.filter(x=>x.sector==sector).length;
      }
    }
  }

  return obj;  
}

//Es una mejora a DevolverComponentes
export const DevolverComponentesConServicios=(componentesBasicos,servicesList)=>{
  servicesList=servicesList.filter(x=>!x.canceled)
  let obj=componentesBasicos
    for (let service of servicesList) {
      obj=MergeObj(obj,JSON.parse(service.components)??{})
    }
  return obj;
}

const MergeObj=(comp1,comp2)=>{
  comp1={...comp2,...comp1}
    for (let aux of Object.keys(comp1)) {
      if (comp2[aux]) {
        comp1[aux]=comp1[aux]<comp2[aux]?comp2[aux]:comp1[aux];
      }
    }
    return comp1;
}

//Devuelve una lista con todas las reservas que hay en cada fecha, repitiendo las reservas de quedarse mas un dia
export const  ObtenerListaPagosFecha=(fecha)=>{
  let lista=[]
  const listaReservas=store.getState().mainReducer.listaReservas.filter(x=>x.state!='cancelada')
  for (let item of listaReservas) {
    if (item.servicesList.length>0) {
        const listaPagos=item.servicesList.filter(x=>!x.canceled).map(x=>{
          if (x.paymentsList.length>0) {
              return x.paymentsList.map(y=>({...y,reservation:item,descripcion:x.description,nombreyape:item.customersList[0].fullName,lugares:item.roomsList.length==0?'-':item.roomsList.map(x=>x.number).join(' ')}));
          }
          else{
            return [];
          }
        }).flat();
        //console.log(listaPagos)
        lista=[...lista,...listaPagos.filter(x=>moment(x.date).startOf('day').diff(moment(fecha).startOf('day'),'days')==0)]
    }
  }
  return lista
}
export const  ObtenerListaPagosFechas=async (from,to,type)=>{
  const obj=type?{from,to,type}:{from,to};
  try {
    const response = await API.graphql({
      query: getPaymentList,
      variables:obj,
      authMode: 'AMAZON_COGNITO_USER_POOLS'
    })
    return response.data.getPaymentList;
  } catch (error) {
    //If no conection alert error
    if (error?.errors[0].message=="Network Error") { 
      alert('No se pudo conectar con el servidor, por favor intente más tarde'); 
      }
  }
}

export const  ObtenerReservasEnCadaFecha=(fechaInicio,fechaFin)=>{
  const noches=moment(fechaFin).diff(fechaInicio,'days')
  const listaReservas=store.getState().mainReducer.listaReservas
  let obj=[]
  let fechaActual;
  for (let index = 0; index < noches; index++) {
    //El -1 es para arreglar algunos bugs que aparecen si chequeamos por horas exactas
    fechaActual=moment(fechaInicio).add(index,'days').add(-1,'hours')
    const listaReservasAux=listaReservas.filter(x=>fechaActual.isBetween(x.checkinEstimated,x.checkoutMade?x.checkoutMade:x.checkoutEstimated, null, '[]'));
    obj=[...obj,{fechaActual:fechaActual.format('YYYY-MM-DD'),listaReservas:listaReservasAux}]
  }

  return obj
}

export const ObtenerEstadisticas=(fechaInicio,fechaFin)=>{
 const listaReservasRepetidas=ObtenerReservasEnCadaFecha(fechaInicio,moment(fechaFin).add(1,'days').format('YYYY-MM-DD')).map(x=>x.listaReservas).flat()
 const resultado={
   mayores:listaReservasRepetidas.reduce((acom,current)=>acom+(current.customersList.filter(x=>x.age>7||x.age==0).length),0),
   menores:listaReservasRepetidas.reduce((acom,current)=>acom+current.customersList.filter(x=>x.age<=7&&x.age>0).length,0),
   vehiculos:listaReservasRepetidas.reduce((acom,current)=>acom+current.vehiclesList.length,0),
   carpas:listaReservasRepetidas.reduce((acom,current)=>acom+(current?.servicesList.length?JSON.parse(current?.servicesList[0]?.components)?.carpa!=undefined?JSON.parse(current.servicesList[0].components).carpa:0:0),0),
   registros:store.getState().mainReducer.listaReservas.filter(x=>moment(x.created).isBetween(fechaInicio,fechaFin, null, '[]')).length
  }
 return resultado
}

export const ObtenerEstadisticasDiarias=(fecha)=>{
  const fechaInicio=moment(fecha).startOf('month').format('YYYY-MM-DD')
  const fechaFin=moment(fecha).endOf('month').add(2,'days').format('YYYY-MM-DD')
  const listaReservasRepetidas=ObtenerReservasEnCadaFecha(fechaInicio,fechaFin)
  const noches=moment(fechaFin).diff(fechaInicio,'days')
  let fechaActual;
  let resultados=[]
  for (let index = 0; index < noches; index++) {
    fechaActual=moment(fechaInicio).add(index,'days')
    let reservasActuales=listaReservasRepetidas.find(x=>x.fechaActual==fechaActual.format('YYYY-MM-DD'))
   
    if (reservasActuales) {
      reservasActuales=reservasActuales.listaReservas
    }
    else{
      continue;
    }
    resultados=[...resultados,{
      fecha:fechaActual.format('YYYY-MM-DD'),
      mayores:reservasActuales.reduce((acom,current)=>acom+current.customersList.filter(x=>x.age>7||x.age==0).length,0),
      menores:reservasActuales.reduce((acom,current)=>acom+current.customersList.filter(x=>x.age<=7&&x.age>0).length,0),
      vehiculos:reservasActuales.reduce((acom,current)=>acom+current.vehiclesList.length,0),
      carpas:reservasActuales.reduce((acom,current)=>acom+(current?.servicesList.length?JSON.parse(current?.servicesList[0]?.components)?.carpa!=undefined?JSON.parse(current.servicesList[0].components).carpa:0:0),0),
      registros:reservasActuales.filter(x=>moment(x.created).diff(fechaActual,'days')==0).length,
      ocupacion:reservasActuales.reduce((acom,current)=>acom+current.roomsList.length,0),
     }]
  }
  /*const resultado={
    mayores:listaReservasRepetidas.reduce((acom,current)=>acom+current.customersList.filter(x=>x.age>7||x.age==0).length,0),
    menores:listaReservasRepetidas.reduce((acom,current)=>acom+current.customersList.filter(x=>x.age<=7&&x.age>0).length,0),
    vehiculos:listaReservasRepetidas.reduce((acom,current)=>acom+current.vehiclesList.length,0)
   }*/

  return resultados
 }

 //Devuelve la estadistica de los registros que estan en el camping
 export const ObtenerEstadisticaActual=()=>{
  const listaReservas=store.getState().mainReducer.listaReservas.filter(x=>x.state=='checkin');
  const obj={
    mayores:listaReservas.reduce((acom,current)=>acom+current.customersList.filter(x=>x.age>7||x.age==0).length,0),
    menores:listaReservas.reduce((acom,current)=>acom+current.customersList.filter(x=>x.age<=7&&x.age>0).length,0),
    vehiculos:listaReservas.reduce((acom,current)=>acom+current.vehiclesList.length,0),
    registros:listaReservas.length,
    carpas:listaReservas.reduce((acom,current)=>acom+(current?.servicesList.length?JSON.parse(current?.servicesList[0]?.components)?.carpa!=undefined?JSON.parse(current.servicesList[0].components).carpa:0:0),0),
    ocupacion:listaReservas.reduce((acom,current)=>acom+current.roomsList.length,0),
   }
  return obj
 }

 //Devuelve la estadistica de los registros que ingresaron hoy
 export const ObtenerEstadisticaIngresosDeHoy=()=>{
  const listaReservas=store.getState().mainReducer.listaReservas.filter(x=>x.state=='checkin'&&moment(x.checkinEstimated).format('YYYY-MM-DD')==moment().format('YYYY-MM-DD'));  const listaReservasSalidas=store.getState().mainReducer.listaReservas.filter(x=>x.state=='checkout'&&moment(x.checkoutMade).format('YYYY-MM-DD')==moment().format('YYYY-MM-DD'));
  const obj={
    mayores:listaReservas.reduce((acom,current)=>acom+current.customersList.filter(x=>x.age>7||x.age==0).length,0),
    menores:listaReservas.reduce((acom,current)=>acom+current.customersList.filter(x=>x.age<=7&&x.age>0).length,0),
    vehiculos:listaReservas.reduce((acom,current)=>acom+current.vehiclesList.length,0),
    registros:listaReservas.length,
    carpas:listaReservas.reduce((acom,current)=>acom+(current?.servicesList.length?JSON.parse(current?.servicesList[0]?.components)?.carpa!=undefined?JSON.parse(current.servicesList[0].components).carpa:0:0),0),
    ocupacion:listaReservas.reduce((acom,current)=>acom+current.roomsList.length,0),
    mayoresSalieron:listaReservasSalidas.reduce((acom,current)=>acom+current.customersList.filter(x=>x.age>7||x.age==0).length,0),
    menoresSalieron:listaReservasSalidas.reduce((acom,current)=>acom+current.customersList.filter(x=>x.age<=7&&x.age>0).length,0)
   }
  return obj
 }

 //Devuelve la estadistica de los registros que ingresaron hoy
 export const ObtenerEstadisticaOcupacion=()=>{
  const listaReservas=store.getState().mainReducer.listaReservas.filter(x=>moment(x.checkinMade).format('YYYY-MM-DD')==moment().format('YYYY-MM-DD'));
  const obj={
    mayores:listaReservas.reduce((acom,current)=>acom+current.customersList.filter(x=>x.age>7||x.age==0).length,0),
    menores:listaReservas.reduce((acom,current)=>acom+current.customersList.filter(x=>x.age<=7&&x.age>0).length,0),
    vehiculos:listaReservas.reduce((acom,current)=>acom+current.vehiclesList.length,0),
    carpas:listaReservas.reduce((acom,current)=>acom+(current?.servicesList.length?JSON.parse(current?.servicesList[0]?.components)?.carpa!=undefined?JSON.parse(current.servicesList[0].components).carpa:0:0),0),
    registros:listaReservas.length
   }
  return obj
 }

export const CalcularCheckoutTime=()=>{
  const checkout=store.getState().mainReducer.checkoutTime;
  const horaActual=parseInt(moment().format('h'))+parseInt(moment().format('a')=='pm'?12:0);
  if (typeof(checkout)=='number') {
    return checkout;
  }
  else{
    for (let i of checkout) {
      if (i.from<=horaActual&&i.to>=horaActual) {
        return i.value
      }
      
    }
  }
}

export const TransformToLocalCurrency=(str,digits=0)=>{
  if(!str){
    str=0;
  }
 return str.toLocaleString('es-ar', {
    style: 'currency',
    currency: 'ars',
    minimumFractionDigits: digits
  })
}
export const MovimientosCaja=()=>{
  const {cashMovements,paymentList,currentUser,isCashAdmin,isAdmin}=store.getState().mainReducer;
  let unionList=[...cashMovements]
  let lista=[]
  if (isAdmin) {
    unionList=[...unionList,...(!paymentList?[]:paymentList.map(x=>({...x,type:x.method,cashName:'abajo',closeCash:false,isWithdrawal:false,date:moment(x.date).add(3,'hours'),concept:x.description})).filter(x=>/*x.method=='Efectivo'&&*/x.canceled!=true))]
  }
  if (!isCashAdmin) {
    unionList=unionList.filter(x=>x.specificUsername==currentUser)
  }
  let saldo=0
  for (let movement of unionList.sort((a, b) => moment(a.date).diff(moment(b.date)))) {
    const obj={}
    const zero=TransformToLocalCurrency(0);
    const isWithdrawal=movement.isWithdrawal;
    saldo=saldo+(isWithdrawal?-movement.amount:movement.amount)
    obj.fecha=moment(movement.date).format('DD/MM HH:mm')
    obj.entrada=isWithdrawal?zero:TransformToLocalCurrency(movement.amount);
    obj.salida=isWithdrawal?TransformToLocalCurrency(movement.amount):zero;
    obj.concepto=movement.concept?Normalizar(movement.concept):"";
    obj.saldo=TransformToLocalCurrency(saldo);
    obj.tipo=movement.type??'Efectivo'
    obj.usuario=movement.specificUsername;
    obj.reservationId=movement.reservationId;
    lista=[...lista,obj];
    obj.salida=isWithdrawal?TransformToLocalCurrency(movement.amount):zero;
  }

  return lista
}
export const ActualizarFromTo=(dispatch)=>{
  const {cashMovements,lastBuildNumber,currentBuildNumber}=store.getState().mainReducer;
  let lista=cashMovements.sort((a, b) => moment(a.date).diff(moment(b.date)));
  let from,to;
  if (lista&&lista.length>0) {
    const encontrado=lista.find(x=>!x.closeCash)
    if (encontrado&&encontrado.date) {
      from=encontrado.date;
    }
    else{
      from='3020-01-19'
    }
    if (lastBuildNumber!=currentBuildNumber) {
      to=lista[lista.length-1].date
    }
    else{
      to='3020-01-20'
    }
  dispatch({type:'SET_CASH_FROM_TO',payload:{from,to}})    
  }
}

export const GeneratePrintTemplate=(campingName,customersList,checkinEstimated,checkoutEstimated,payed,due)=>(
  `<!DOCTYPE html>
  <html>
  <head>
  <style>
  .flex-container-row {      
    display: flex;
    justify-content: space-around;
  }
  .flex-container-column {      
    display: flex;
    flex-direction:column;
  }
  ​
  .flex-container > div {
    background-color: #f1f1f1;
    width: 100px;
    margin: 10px;
    text-align: center;
    line-height: 75px;
    font-size: 30px;
  }
  h1{
      text-align:center;
  }
  h3{
      text-align:center;
  }
  p{
      text-align:center;
  }
  .fz-18{
      font-size:19px;
  }
  .mt-0{
      margin-top:0px !important;
  }
  table{
    border-collapse: collapse;
  }
  th,td {
    border: 1px solid black;
    padding: 8px;
  }
  </style>
  </head>
  <body>
 <div class="flex-container-column">
  <h1>Voucher de reserva</h1>
  <h3 class="mt-0">${campingName}</h3>
  <div class="flex-container-row">
    <div class="flex-container-column">
      <div class="fz-18">Fecha ingreso</div>
      <p>${moment(checkinEstimated).format('DD MMM YYYY')}</p>
    </div>
    <div class="flex-container-column">
      <div class="fz-18">Fecha egreso</div>
      <p>${moment(checkoutEstimated).format('DD MMM YYYY')}</p>
    </div>
    </div>
      <table>
        <tr>
          <th>Nombre y apellido</th>
          <th>DNI</th>
          <th>Edad</th>
          <th>Procedencia</th>
        </tr>
        ${customersList.map(x=>(
          `<tr>
          <td>${x.fullName}</td>
          <td>${x.dni==null?"-":x.dni}</td>
          <td>${(x.age==0||!x.age)?"-":x.age}</td>
          <td>${x.geo&&x.geo!='{}'?JSON.parse(x.geo).value:"-"}</td>
        </tr>`
        ))}
      	</table>
      <table style="margin-top:10px">
        <tr>
          <th>Abonado</th>
          <th>Adeudado</th>
        </tr>
        <tr style="text-align:center">
          <td>$${payed}</td>
          <td>$${due}</td>
        </tr>
      	</table>
      </div>
      

  </body>
  </html>
  `
)

export const beforePrint=async()=>{
  const dispatch=store.dispatch;
  dispatch({type:'SET_PRINTER_PRINTING',payload:true})
  if (!qz.websocket.isActive()) {
    await connectPrinter()
  }}

export const afterPrint=async()=>{
 const dispatch=store.dispatch;
 dispatch({type:'SET_PRINTER_PRINTING',payload:false})
}

export const printPOS=async(payload,type)=>{
  await beforePrint();
  const {printerName,margings}=store.getState().printerReducer.printer
  let config = qz.configs.create(printerName,
  {
    margins: margings, 
  })
  let template=''
  switch (type) {
    case 'checkin':
      template=CheckinSignature(payload) 
      break;
    case 'checkout':
      template=CheckoutSignature(payload) 
      break;
    case 'ticketx':
      console.log(payload)
      {
      template=TicketX(
        payload.date,
        payload.paymentsList[0].method,
        [{
          name:payload.name,
          quantity:payload.paymentsList[0].quantity,
          price:payload.paymentsList[0].amount,
          total:payload.paymentsList[0].amount,
          description:payload.description
        }]
      )
    }
      break;
    case 'ticketb':
      {
      const {bill,comprobanteNro,cae,dateExpireCae,listProduct,afipQR,payCondition}=payload
      const billingInfo=store.getState().billReducer;
      template=TicketFacturaB(
        billingInfo.fantasyName,
        billingInfo.razonSocial,
        billingInfo.CUIT,
        billingInfo.address,
        billingInfo.startActivity,
        billingInfo.ingresosBrutos,
        comprobanteNro,
        bill.dateBill,
        payCondition,
          listProduct.map(x=>({
          name:x.name,
          quantity:x.quantity,
          price:x.price,
          total:x.total,
          description:x.description
        })),
        cae,
        dateExpireCae,
        afipQR
      )
      }
      break;
  }
  console.log(template)
  try {
    await qz.print(config, [{
      type: 'pixel',
      format: 'html',
      flavor: 'plain',
      data:template
    }]);

  } catch (error) {
    console.log(error)
    alert('Hubo un error y no se pudo imprimir')
  }

  await afterPrint();
}

export const connectPrinter=async()=>{
  const dispatch=store.dispatch;
  const printer=store.getState().printerReducer.printer;
  dispatch({type:'SET_PRINTER_CONNECTING',payload:true})
  const host=printer.host
  const port=printer.port
  qz.websocket.setErrorCallbacks(() => {
    qz.websocket.disconnect();
  })
  window.onbeforeunload = function() {
    qz.websocket.disconnect();

};
  try {
    const response=await qz.websocket.connect({host:host?host:"localhost",usingSecure:true,keepAlive:true,port:{secure:[port]}})
    qz.websocket.setErrorCallbacks((err)=>{
       dispatch({type:"SET_PRINTER_CONNECTED",payload:false})
    })
      
    console.log("PRINTER CONECTED")
    await dispatch({type:"SET_PRINTER_CONNECTED",payload:true})

  } catch (error) {
    await dispatch({type:"SET_PRINTER_CONNECTED",payload:false})

  }
  dispatch({type:'SET_PRINTER_CONNECTING',payload:false})

}

export const isConsumidorFinal=(totalPayed,payCondition)=>{
  const {finalConsumerLimit,finalConsumerLimitElectronic} = store.getState().billReducer;
  if(payCondition=='Efectivo'||payCondition=='Contado'){
    return totalPayed<finalConsumerLimit
  }else{
    return totalPayed<finalConsumerLimitElectronic
  }
}

//giving two random coords of a rectangle, returns the 4 points of the rectangle
//coordsList example [2493,366,2595,474]
export const getRectanglePoints=(coordsList)=>{
  let [x1,y1,x2,y2]=coordsList
  if(x1>x2){
    const aux=x1
    x1=x2
    x2=aux
  }
  if(y1>y2){
    const aux=y1
    y1=y2
    y2=aux
  }
  return [
    x1,y1,
    x2,y1,
    x2,y2,
    x1,y2
  ]
}


export const findTopRightRectCorner=({coords})=>
{
  const newCoords=getRectanglePoints(coords)
  const x=newCoords.reduce((acc,cur)=>cur>acc?cur:acc)
  const y=newCoords.reduce((acc,cur)=>cur<acc?cur:acc)
  return [x,y]
}

export const findTopRightPolyCorner=({coords})=>{
  //find max element in coords
  const max=coords.reduce((acc,cur)=>cur>acc?cur:acc)
  const min=coords.reduce((acc,cur)=>cur<acc?cur:acc)
  let topRightCorner=[max,min]
  return topRightCorner
}