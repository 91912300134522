const defaultState={
    toggleBilling:false,
    finalConsumerLimit:13115,
    finalConsumerLimitElectronic:26230,
}

export default function reducer(state=defaultState,{type,payload}){
    switch (type){
        case "SET_BILLING_INFO":
            return {...state,...payload};
        case "SET_TOGGLE_BILLING":
            return {...state,toggleBilling:payload};
        default:
            return state
    }
}